import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponent } from './components/custom-components/forms/form/form.component';
import { InputComponent } from './components/custom-components/forms/fields/input/input.component';
import { TextAreaComponent } from './components/custom-components/forms/fields/text-area/text-area.component';
import { SelectComponent } from './components/custom-components/forms/fields/select/select.component';
import { ButtonComponent } from './components/base-components/button/button.component';
import { PinInputComponent } from './components/custom-components/forms/fields/pin-input/pin-input.component';
import { MarqueeComponent } from './components/base-components/marquee/marquee.component';
import { IconComponent } from './components/base-components/icon/icon.component';
import { BaseHeaderComponent } from './components/custom-components/headers/base-header/base-header.component';
import { PreLaunchHeaderComponent } from './components/custom-components/headers/pre-launch-header/pre-launch-header.component';
import { BaseFooterComponent } from './components/custom-components/footers/base-footer/base-footer.component';
import { PreLaunchFooterComponent } from './components/custom-components/footers/pre-launch-footer/pre-launch-footer.component';
import { PublicHeaderComponent } from './components/custom-components/headers/public-header/public-header.component';
import { PublicFooterComponent } from './components/custom-components/footers/public-footer/public-footer.component';
import { HeroSectionComponent } from './components/base-components/hero-section/hero-section.component';
import { LogoComponent } from './components/base-components/logo/logo.component';
import { FormsModule } from '@angular/forms';
import { LottieModule } from 'ngx-lottie';
import { HeroBlurUnderlayComponent } from './components/custom-components/hero-blur-underlay/hero-blur-underlay.component';
import { AuthOptionsComponent } from './components/custom-components/auth/auth-options/auth-options.component';
import { AuthPageReturnComponent } from './components/custom-components/auth/auth-page-return/auth-page-return.component';
import { AuthWizardItemComponent } from './components/custom-components/auth/auth-wizard-item/auth-wizard-item.component';
import { RouterModule } from '@angular/router';
import { InputGroupComponent } from './components/custom-components/forms/fields/input-group/input-group.component';
import { InputFileUploadComponent } from './components/custom-components/forms/fields/input-file-upload/input-file-upload.component';
import { InputMultiSelectComponent } from './components/custom-components/forms/fields/input-multi-select/input-multi-select.component';

@NgModule({
  declarations: [
    FormComponent,
    InputComponent,
    TextAreaComponent,
    SelectComponent,
    ButtonComponent,
    PinInputComponent,
    MarqueeComponent,
    IconComponent,
    BaseHeaderComponent,
    PreLaunchHeaderComponent,
    BaseFooterComponent,
    PreLaunchFooterComponent,
    PublicHeaderComponent,
    PublicFooterComponent,
    HeroSectionComponent,
    LogoComponent,
    HeroBlurUnderlayComponent,
    AuthOptionsComponent,
    AuthPageReturnComponent,
    AuthWizardItemComponent,
    InputGroupComponent,
    InputFileUploadComponent,
    InputMultiSelectComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    LottieModule,
  ],
  exports: [
    FormComponent,
    InputComponent,
    TextAreaComponent,
    SelectComponent,
    ButtonComponent,
    PinInputComponent,
    MarqueeComponent,
    IconComponent,
    BaseHeaderComponent,
    PreLaunchHeaderComponent,
    BaseFooterComponent,
    PreLaunchFooterComponent,
    PublicHeaderComponent,
    PublicFooterComponent,
    HeroSectionComponent,
    LogoComponent,
    HeroBlurUnderlayComponent,
    AuthOptionsComponent,
    AuthPageReturnComponent,
    AuthWizardItemComponent,
    InputGroupComponent,
    InputFileUploadComponent,
    InputMultiSelectComponent,
  ]
})
export class SharedModule { }
