<div class="flex max-[650px]:flex-col px-6 py-20 mx-auto min-h-screen md:h-fit pt:mt-0">
    <div class="flex-none">
        <hg-button (click)="returnToPreviousPage()" class="w-full" [iconPosition]="'left'" type="transparent" icon="arrow_left_circle_b" [iconColor]="'dark'" [iconColorVariant]="'light'" label="Back"></hg-button>
    </div>
    <div class="flex-auto w-full px-3 min-[1400px]:px-[22%] min-[2550px]:px-[30%] max-[650px]:mt-20 rounded-lg text-dark-blue dark:text-white">
        <div class="flex flex-col justify-center ">
            <h2 class="text-4xl text-[2rem]">
                <ng-content select="[pageTitle]"></ng-content>
            </h2>
            <p>
                <ng-content select="[pageSubTitle]"></ng-content>
            </p>
            <div>
                <ng-content select="[pageContent]"></ng-content>
            </div>
        </div>
    </div>
</div>

  