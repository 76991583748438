import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormDetails } from 'src/app/shared/models/shared/form/form-details';
import { FormValue } from 'src/app/shared/models/shared/form/form-value';
import { CreateOrgFormDetails } from 'src/assets/static-forms/auth/create-org-form';

@Component({
  selector: 'hg-create-organization',
  templateUrl: './create-organization.component.html',
  styleUrls: ['./create-organization.component.scss']
})
export class CreateOrganizationComponent {
  createOrgFormDetails: FormDetails = CreateOrgFormDetails;

  constructor(private router: Router){}

  handleSubmit(value: FormValue): void {
    this.router.navigate(['/auth/welcome']);
    // routerLink="/auth/welcome"
  }
}
