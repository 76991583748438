import { Component } from '@angular/core';

@Component({
  selector: 'hg-auth-page-return',
  templateUrl: './auth-page-return.component.html',
  styleUrls: ['./auth-page-return.component.scss']
})
export class AuthPageReturnComponent {
  returnToPreviousPage(): void {
    window.history.back();
  }
}
