import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { StateDetails } from 'src/app/shared/models/state-management/state';
import { toggleDarkMode } from 'src/app/shared/state-management/actions/app.actions';

@Component({
  selector: 'hg-theme-selection',
  templateUrl: './theme-selection.component.html',
  styleUrls: ['./theme-selection.component.scss']
})
export class ThemeSelectionComponent {
  darkMode!: boolean;
  appState$: Observable<StateDetails>

  constructor(
    private store: Store<{stateReducer: StateDetails}>
  ) {
    this.appState$ = this.store.select('stateReducer');
    this.appState$.subscribe(
      res => {
        this.darkMode = res.darkMode as boolean;
      }
    )
  }

  toggleDarkMode(darkMode: boolean): void {
    this.store.dispatch(toggleDarkMode({darkMode}));
  }
}
