import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { FormDetails } from 'src/app/shared/models/shared/form/form-details';
import { FormValue } from 'src/app/shared/models/shared/form/form-value';
import { StateDetails } from 'src/app/shared/models/state-management/state';
import { GoogleAuthService } from 'src/app/shared/services/auth/google-auth/google-auth.service';
import { CodeLoginFormDetails } from 'src/assets/static-forms/auth/code-login';

@Component({
  selector: 'hg-email-code-check',
  templateUrl: './email-code-check.component.html',
  styleUrls: ['./email-code-check.component.scss']
})
export class EmailCodeCheckComponent {
  codeLoginFormDetails: FormDetails = CodeLoginFormDetails;
  manualAuth: boolean = false;
  authType!: string;
  authDetails!: {email: string, type: string};
  appState$!: Observable<StateDetails>;

  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    public googleAuth: GoogleAuthService,
    private store: Store<{stateReducer: StateDetails}>
  ) {
      this.route.params.subscribe((params) => {
        this.authType = params['type'];
        this.fetchAuthDetails();
      })
  }

  async fetchAuthDetails(): Promise<void> {
    this.appState$ = this.store.select('stateReducer');
    this.appState$.subscribe(
      res => {
        this.authDetails = res.authDetails as any;
      }
    );
    if (this.authDetails.type === 'social') {
      this.decodeSocialAuthToken();
    }
  }

  decodeSocialAuthToken(): void {
    this.route.fragment.subscribe((fragment) => {
      const params = new URLSearchParams(fragment as string);
      const id_token = params.get('id_token');
      this.googleAuth.updateAuthDetails(id_token as string);
    })
  }

  handleSubmit(value: FormValue): void {
    // (this.codeLoginFormDetails.fields.at(0) as FormField).customError = 'Wrong Login Code';
    this.router.navigate(['/auth/create-organization']);
  }

  toggleManualAuth(): void {
    this.manualAuth = true;
  }
}
