import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PreLaunchRoutingModule } from './pre-launch-routing.module';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    ComingSoonComponent
  ],
  imports: [
    CommonModule,
    PreLaunchRoutingModule,
    SharedModule
  ]
})
export class PreLaunchModule { }
