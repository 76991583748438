import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { StateDetails } from 'src/app/shared/models/state-management/state';

@Component({
  selector: 'hg-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {
  @Input() darkMode: boolean = false;
  @Input() themeToggle: boolean = false;
  appState$!: Observable<StateDetails>;
  
  constructor(private store: Store<{stateReducer: StateDetails}>){}

  ngOnInit(): void {
    if (this.themeToggle) {
      this.appState$ = this.store.select('stateReducer');
      this.appState$.subscribe(
        res => {
          this.darkMode = res.darkMode as boolean;
        }
      )
    }
  }
}
