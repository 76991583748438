import { Component } from '@angular/core';
import { State, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { StateDetails } from './shared/models/state-management/state';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  appState$: Observable<StateDetails>;
  darkMode: boolean = false;
  
  constructor(private store: Store<{stateReducer: StateDetails}>){
    this.appState$ = this.store.select('stateReducer');
    this.appState$.subscribe(
      res => {
        this.darkMode = res.darkMode as boolean;
      }
    )
  }
}
