<hg-auth-wizard-item [currentStep]="3">
    <span title>
        Awesome!
    </span>
    <span subtitle>            
        Welcome once again to Hagile. <br>
        Next, explore the various features and start your product development in an Hagile Way
    </span>
    <span content>
        <hg-button routerLink="/" [docked]="true" label="Explore Hagile" icon="hagile" [iconColor]="'light'"></hg-button>
    </span>
</hg-auth-wizard-item>