<hg-auth-page-return>
    <span pageTitle>
        Create a new organization
    </span>
    <span pageSubTitle class="text-greyish-blue">
        Organizations serve as collaborative environments where teams <br> 
        can collectively engage in projects, cycles and tasks.
    </span>
    <div class="mt-20 space-y-6" pageContent>
        <div> 
            <hg-icon name="lock" type="grey"></hg-icon> 
            <span class="align-sub ml-2.5">Connection Secured </span>
        </div>
        <div>
            <hg-form [formDetails]="createOrgFormDetails" (form)="handleSubmit($event)">
                <div formButtonSlot class="w-full">
                    <hg-button class="w-full" [docked]="true" type="primary" label="Create Organization"></hg-button>
                </div>
            </hg-form>
        </div>
    </div>
</hg-auth-page-return>