<hg-base-footer>
    <li footerItemsSlot>
        <div class="font-display text-sm font-semibold tracking-wider text-neutral-950">Company</div>
        <ul role="list" class="my-4 text-sm text-neutral-700">
            <li class="mt-4">
            <a class="transition hover:text-neutral-950" href="/work/family-fund">Terms and Conditions</a>
            </li>
            <li class="mt-4">
            <a class="transition hover:text-neutral-950" href="/work/unseal">Unseal</a>
            </li>
            <li class="mt-4">
            <a class="transition hover:text-neutral-950" href="/work/phobia">Phobia</a>
            </li>
        </ul>
    </li>
</hg-base-footer>