import { Component } from '@angular/core';

@Component({
  selector: 'hg-base-footer',
  templateUrl: './base-footer.component.html',
  styleUrls: ['./base-footer.component.scss']
})
export class BaseFooterComponent {

}
