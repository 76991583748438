import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ButtonIconPosition, ButtonSize, ButtonType } from 'src/app/shared/models/shared/button/button-details';
import { IconSize, IconType } from 'src/app/shared/models/shared/icon/icon-details';
import { StateDetails } from 'src/app/shared/models/state-management/state';

@Component({
  selector: 'hg-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() label!: string;
  @Input() themeToggle: boolean = false;
  @Input() action!: string;
  @Input() type: ButtonType = 'primary';
  @Input() variant!: ButtonType;
  @Input() icon!: string;
  @Input() iconPosition: ButtonIconPosition = 'left';
  @Input() iconColor!: IconType;
  @Input() iconColorVariant!: IconType;
  @Input() docked: boolean = false;
  @Input() size: ButtonSize | IconSize = 'sm';
  @Input() disabled: boolean = false;
  buttonClasses: string = '';
  buttonType!: ButtonType;
  appState$!: Observable<StateDetails>;

  constructor(
    private store: Store<{stateReducer: StateDetails}>
  ){}

  ngOnInit(): void {
    this.buildDefaultButton();

    if (this.themeToggle) {
      this.handleThemeToggle();
    }
  }

  getButtonClasses(): string {
    const classes = [];
    classes.push(this.getButtonDisplayClasses());
    classes.push(this.getButtoIconOrientation());
    if (this.docked) {
      classes.push('py-3 px-20 sm:px-36')
    } else if (this.buttonType !== 'basic') {
      classes.push(this.getButtonSpacingClasses());
    }

    return classes.join(' ');
  }

  getButtonDisplayClasses(): string {
    switch (this.buttonType) {
      case 'primary':
          return 'bg-dark-blue text-white';
      case 'ghost':
          return 'bg-whitish-blue text-dark-blue border border-bright-blue';
      case 'transparent':
          return 'bg-transparent text-dark-blue dark:text-white border border-bright-blue';
      case 'disabled':
          return 'bg-greyish-blue text-whitish-blue';
      case 'cta':
          return 'bg-gradient-to-r from-violet to-bright-blue text-white';
      default:
          return ''
    }
  }

  getButtoIconOrientation(): string {
    return this.iconPosition === 'right' ? 'flex-row-reverse' : 'flex-row' ;
  }

  getButtonSpacingClasses(): string {
    switch (this.size) {
      case 'sm':
          return this.label ? 'py-2.5 px-6' : 'p-2.5';
      case 'md':
          return this.label ? 'py-3 px-6' : 'p-3';
      case 'lg':
          return this.label ? 'py-4 px-6' : 'p-4';

      default:
        return '';
    }
  }

  buildVariantButton(): void {
    this.buttonType = this.variant ? this.variant : this.type;
    this.buttonClasses = this.getButtonClasses();
  }

  buildDefaultButton(): void {
    this.buttonType = this.type;
    this.buttonClasses = this.getButtonClasses();
  }

  handleThemeToggle(): void {
    this.appState$ = this.store.select('stateReducer');
      this.appState$.subscribe(
        res => {
           res.darkMode ? 
            this.buildVariantButton() : 
            this.buildDefaultButton(); 
        }
      )
  }
}
