<div class="relative isolate px-6 lg:px-8">
    <div class="mx-auto px-0 min-[1400px]:px-8" [ngClass]="{
        'pt-40 min-[1400px]:pt-36 max-w-7xl flex flex-col lg:flex-row items-center justify-between pb-32': rowOriented, 
        'max-w-3xl sm:pt-48 lg:pt-48': !rowOriented}"
    >
      <div [ngClass]="{'text-left max-[1399px]:flex-1 min-[1400px]:w-2/5': rowOriented, 
      'text-center flex-1': !rowOriented}">
        <ng-content select="[heroContentSlot]"></ng-content>
      </div>
      <div [ngClass]="{
        'max-[1399px]:flex-1 min-[1400px]:w-3/5 mt-10 lg:mt-0': rowOriented, 
        'sm:my-8 sm:flex sm:justify-center flex-1': !rowOriented}">
        <ng-lottie
          class="overflow-hidden"
          [options]="options">
        </ng-lottie>
        <hg-hero-blur-underlay [rowOriented]="rowOriented"></hg-hero-blur-underlay>
      </div>
    </div>
</div>