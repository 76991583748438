import { Component } from '@angular/core';

@Component({
  selector: 'hg-pre-launch-footer',
  templateUrl: './pre-launch-footer.component.html',
  styleUrls: ['./pre-launch-footer.component.scss']
})
export class PreLaunchFooterComponent {

}
