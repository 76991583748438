<div class="relative mt-6">
    <input 
      #ctrl="ngModel"
      [id]="fieldDetails.name" 
      [type]="fieldDetails.type" 
      [(ngModel)]="fieldDetails.value"
      placeholder="{{fieldDetails.placeholder}}"
      pattern="{{fieldDetails.pattern}}"
      autocomplete="{{fieldDetails.autocomplete}}"
      disabled="{{fieldDetails.disabled}}"
      required="{{fieldDetails.required}}"
      (input)="resetField()"
      class="peer rounded-xl placeholder-transparent py-5 px-8 w-full border bg-transparent text-dark-blue dark:text-whitish-blue" 
      [ngClass]="{
        'border-grey-600': !ctrl.touched && !ctrl.dirty,
        'border-error mb-6': (ctrl.touched && ctrl.dirty && ctrl.errors) || fieldDetails.customError,
        'border-success': ctrl.touched && ctrl.dirty && !ctrl.errors && !fieldDetails.customError
      }"
    />
    <label 
      for="{{fieldDetails.name}}" 
      class="absolute left-8 -top-2.5 py-1 px-2 rounded-lg text-sm transition-all bg-white text-gradient-b-end dark:text-greyish-blue peer-placeholder-shown:p-0 peer-placeholder-shown:bg-transparent peer-placeholder-shown:top-5 peer-placeholder-shown:text-base peer-focus:-top-3 peer-focus:bg-white peer-focus:text-sm peer-focus:px-2 peer-focus:py-1 peer-focus:rounded-lg dark:peer-placeholder-shown:text-greyish-blue/[0.5] peer-placeholder-shown:text-dark-blue/[0.5] peer-focus:text-gradient-b-end dark:peer-focus:text-greyish-blue">
        {{fieldDetails.label}}
    </label>
    <button
        class="absolute inset-y-0 right-0 px-4 py-2 bg-transparent text-violet rounded-r-lg hover:bg-blue-600 focus:outline-none"
    >
        {{ fieldDetails.subText }}
    </button> 
    <div *ngIf="!fieldDetails.cleared && (ctrl.touched && ctrl.dirty && ctrl.errors) || fieldDetails.customError" class="absolute left-0 top-20 text-error text-sm">
      <div *ngIf="ctrl.errors && !fieldDetails.customError">
        <div *ngIf="ctrl.errors['required']">
          <hg-icon name="warning" type="error" size="sm" class="align-text-bottom"></hg-icon>
          This field is required
        </div>
        <div *ngIf="ctrl.errors['pattern']">
          <hg-icon name="warning" type="error" size="sm" class="align-text-bottom"></hg-icon>
          Invalid input.
        </div>
      </div>
      <div *ngIf="fieldDetails.customError">
        <hg-icon name="warning" type="error" size="sm" class="align-text-bottom"></hg-icon>
        {{ fieldDetails.customError }}
      </div>
    </div>    
</div> 