import { Component, Input, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'hg-hero-section',
  templateUrl: './hero-section.component.html',
  styleUrls: ['./hero-section.component.scss']
})
export class HeroSectionComponent implements OnInit {
  @Input() rowOriented: boolean = true;
  options: AnimationOptions = {
    path: '/assets/animations/hero-animation.json',
  }; 

  ngOnInit(): void{
    
  }
}
