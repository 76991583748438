<div class="relative">
  <div 
    class="flex flex-row justify-center space-x-4"
    [ngClass]="{'mb-12': fieldDetails.customError}"
  >
    <input
      type="text"
      class="flex-shrink-0 w-[14%] sm:px-6 py-4 sm:text-2xl text-center border text-dark-blue rounded-xl focus:ring focus:ring-blue-400"
      [maxLength]="fieldDetails.maxLength"
      [(ngModel)]="pin[0]" 
      (input)="onInputChange(0)"
      (keydown)="onKeyDown($event, 0)"
      required
      id="pin0"
      [ngClass]="{
        'border-grey-700': pin[0].length < 0,
        'border-success': pin[0].length > 0 && !fieldDetails.customError,
        'border-error': fieldDetails.customError
      }"
    />
    <input
      type="text"
      class="flex-shrink-0 w-[14%] sm:px-6 py-4 sm:text-2xl text-center border text-dark-blue rounded-xl focus:ring focus:ring-blue-400"
      [maxLength]="fieldDetails.maxLength"
      [(ngModel)]="pin[1]" 
      (input)="onInputChange(1)"
      (keydown)="onKeyDown($event, 1)"
      required
      id="pin1"
      [ngClass]="{
        'border-grey-700': pin[1].length < 0,
        'border-success': pin[1].length > 0 && !fieldDetails.customError,
        'border-error': fieldDetails.customError
      }"
    />
    <input
      type="text"
      class="flex-shrink-0 w-[14%] sm:px-6 py-4 sm:text-2xl text-center border text-dark-blue rounded-xl focus:ring focus:ring-blue-400"
      [maxLength]="fieldDetails.maxLength"
      [(ngModel)]="pin[2]" 
      (input)="onInputChange(2)"
      (keydown)="onKeyDown($event, 2)"
      required
      id="pin2"
      [ngClass]="{
        'border-grey-700': pin[2].length < 0,
        'border-success': pin[2].length > 0 && !fieldDetails.customError, 
        'border-error': fieldDetails.customError
      }"
    />
    <input
      type="text"
      class="flex-shrink-0 w-[14%] sm:px-6 py-4 sm:text-2xl text-center border text-dark-blue rounded-xl focus:ring focus:ring-blue-400"
      [maxLength]="fieldDetails.maxLength"
      [(ngModel)]="pin[3]" 
      (input)="onInputChange(3)"
      (keydown)="onKeyDown($event, 3)"
      required
      id="pin3"
      [ngClass]="{
        'border-grey-700': pin[3].length < 0,
        'border-success': pin[3].length > 0 && !fieldDetails.customError,
        'border-error': fieldDetails.customError
      }"
    />
    <input
      type="text"
      class="flex-shrink-0 w-[14%] sm:px-6 py-4 sm:text-2xl text-center border text-dark-blue rounded-xl focus:ring focus:ring-blue-400"
      [maxLength]="fieldDetails.maxLength"
      [(ngModel)]="pin[4]" 
      (input)="onInputChange(4)"
      (keydown)="onKeyDown($event, 4)"
      required
      id="pin4"
      [ngClass]="{
        'border-grey-700': pin[4].length < 0,
        'border-success': pin[4].length > 0 && !fieldDetails.customError,
        'border-error': fieldDetails.customError
      }"
    />
    <input
      type="text"
      class="flex-shrink-0 w-[14%] sm:px-6 py-4 sm:text-2xl text-center border text-dark-blue rounded-xl focus:ring focus:ring-blue-400"
      [maxLength]="fieldDetails.maxLength"
      [(ngModel)]="pin[5]"
      (input)="onInputChange(5)"
      (keydown)="onKeyDown($event, 5)"
      required
      id="pin5"
      [ngClass]="{
        'border-grey-700': pin[5].length < 0,
        'border-success': pin[5].length > 0 && !fieldDetails.customError,
        'border-error': pin[5].length > 0 && fieldDetails.customError
      }"
    />
  </div>
  <div *ngIf="fieldDetails.customError" class="absolute left-2 top-20 text-error text-sm">
      <hg-icon name="warning" type="error" size="sm" class="align-text-bottom"></hg-icon>
      {{ fieldDetails.customError }}
  </div>
</div>
  