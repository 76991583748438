import { FormDetails } from "src/app/shared/models/shared/form/form-details";

export const AuthEmailOptionFormDetails: FormDetails = {
    name: 'email-auth',
    fieldOrientation: 'column',
    buttonOrientation: 'column',
    displayFields: false,
    fields: [
        {
            fieldType: 'input',
            type: 'email',
            name: 'email',
            hasLabel: true, 
            placeholder: 'Enter your email address',
            label: 'Enter your email address',
            minLength: 1,
            required: true, 
            disabled: false,
            subText: 'Show',
            pattern: '^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$'
        }
    ]
}

export const AuthSamlOptionFormDetails: FormDetails = {
    name: 'saml-auth',
    fieldOrientation: 'column',
    buttonOrientation: 'column',
    displayFields: false,
    fields: [
        {
            fieldType: 'input',
            type: 'email',
            name: 'email',
            hasLabel: true, 
            placeholder: 'Enter your email address',
            label: 'Enter your email address',
            minLength: 1,
            required: true, 
            disabled: false,
            subText: 'Show',
            pattern: '^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$'
        }
    ]
}