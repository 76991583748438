import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'hg-auth-wizard-item',
  templateUrl: './auth-wizard-item.component.html',
  styleUrls: ['./auth-wizard-item.component.scss']
})
export class AuthWizardItemComponent {
  @Input() currentStep: 0 | 1 | 2 | 3 = 0;
  steps: string[] = [
    '/auth/welcome',
    '/auth/theme-selection',
    '/auth/invite-members',
    '/auth/onboarding-complete'
  ]
}
