<object
    async 
    [data]="iconSrc" 
    type="image/svg+xml" 
    id="svgObject" 
    alt="hagile-icon"
    [ngClass]="{'align-text-top': alignToText, 'hidden': !iconReady, 'inline-block': iconReady}">
</object>

<svg [ngClass]="{'align-text-top': alignToText, 'hidden': iconReady, 'inline-block': !iconReady}" height="16" width="16" class="animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
</svg>