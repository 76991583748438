import {Action, createReducer, on, State} from '@ngrx/store';
import { StateDetails } from '../../models/state-management/state';
import * as stateUpdate from '../actions/app.actions';

export const initialState: StateDetails = {
  darkMode: false,
  authDetails: null
};

const stateReducer = createReducer(
  initialState,
  on(stateUpdate.toggleDarkMode, (state, { darkMode }) =>
    ({
      ...state, darkMode
    }),
  ),

  on(stateUpdate.setAuthDetails, (state, { authDetails }) =>
    ({
      ...state, authDetails
    }),
  ),
);

export function appReducer(state: StateDetails | undefined, action: Action) {
  return stateReducer(state, action);
}
