<div>
  <hg-pre-launch-header></hg-pre-launch-header>
  <main>
    <hg-hero-section [rowOriented]="true">
      <div class="mt-10" heroContentSlot>
        <h1 class="text-8xl text-dark-blue">
          Build the Hagile Way
        </h1>
        <p class="mt-10 mb-12 text-lg leading-8 text-greyish-blue">
          Empower your team with Hagile's no-code automation to save time, maintain focus, and streamline work processes effortlessly.
        </p>
        <h3 *ngIf="!displayForm" class="text-xl text-dark-blue"> Thank you for joining the Hagile waitlist, you will be the first to know about launch date and new features </h3>
        <h3 *ngIf="displayForm" class="text-xl text-dark-blue"> Get notified when we launch </h3>
        <hg-form #formComponent *ngIf="displayForm" [formDetails]="formDetails" (form)="handleFormSubmission($event)"></hg-form>
        <div class="absolute inset-x-0 -z-10 transform-gpu blur-3xl overflow-hidden hidden lg:block lg:top-[calc(100%-15rem)]" aria-hidden="true">
          <div class="relative overlay-shape opacity-20 aspect-[1155/678] bg-[#0F73ED] rounded-3xl sm:w-[31.125rem] sm:h-[21.5325rem] rotate-[-26.43deg] min-[1400px]:w-[36.125rem] min-[1400px]:h-[26.5325rem] left-[calc(50%-15rem)] lg:left-[calc(50%-40rem)] min-[1400px]:left-[calc(50%-60rem)]"></div>
        </div>
      </div>
    </hg-hero-section>
  </main>
  <section id="marquee" class="-mt-28 mb-6">
    <hg-marquee [darkMode]="true" iconColor="violet"></hg-marquee>
  </section>
  <hg-pre-launch-footer></hg-pre-launch-footer>
</div>
  