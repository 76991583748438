<footer class="px-6 lg:px-8 bg-dark-blue text-white">
    <div class="mx-auto max-w-4xl">
      <div class="py-20">
        <div class="grid grid-cols-1 gap-x-8 gap-y-16 lg:grid-cols-2">
          <div class="flex lg:justify-start">
            <div class="max-w-sm">
              <hg-logo class="h-[7.15rem] block" [darkMode]="true"></hg-logo>
              <h2 class="font-light mt-5 mb-14 text-lg tracking-wider text-white">
                Managing your agile process and team with ease
              </h2>
              <p class="mt-4 text-sm">
                  <hg-icon name="instagram" size="xl" type="light"></hg-icon>
                  <hg-icon name="github" size="xl" type="light" class="mx-2.5"></hg-icon>
                  <hg-icon name="twitter_s" size="xl" type="light"></hg-icon>
              </p>
            </div>
          </div>
          <nav class="flex lg:justify-end lg:flex-col">
            <ul role="list" class="text-end">
              <ng-content select="[footerItemsSlot]"></ng-content>
              <li>
                <div class="font-light text-sm tracking-wider">
                  <span class="text-xs align-text-top"> &copy; </span>
                  <span class="text-base"> Tateyru. All Right Reserved </span>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
</footer>