import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { AuthComponent } from './auth.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { EmailCodeCheckComponent } from './pages/onboarding/email-code-check/email-code-check.component';
import { CreateOrganizationComponent } from './pages/onboarding/create-organization/create-organization.component';
import { WelcomePageComponent } from './pages/onboarding/welcome-page/welcome-page.component';
import { ThemeSelectionComponent } from './pages/onboarding/theme-selection/theme-selection.component';
import { InviteMembersComponent } from './pages/onboarding/invite-members/invite-members.component';
import { OnboardingCompletionComponent } from './pages/onboarding/onboarding-completion/onboarding-completion.component';


@NgModule({
  declarations: [
    AuthComponent,
    SignInComponent,
    SignUpComponent,
    EmailCodeCheckComponent,
    CreateOrganizationComponent,
    WelcomePageComponent,
    ThemeSelectionComponent,
    InviteMembersComponent,
    OnboardingCompletionComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    SharedModule,
  ]
})
export class AuthModule { }
