<div class="flex flex-col items-center justify-center px-10 mx-auto md:h-screen py-20 text-center">
    <div class="w-full max-w-4xl rounded-lg flex flex-col items-center justify-center text-dark-blue dark:text-white">
        <h1 class="text-6xl text-[4rem]">
            <ng-content select="[title]"></ng-content>
        </h1>
        <p class="text-greyish-blue text-center mt-1.5 mb-2 sm:px-36"> 
            <ng-content select="[subtitle]"></ng-content>
        </p>
        <ng-content select="[options]"></ng-content>
        <div class="mt-20 space-y-36">
            <ng-content select="[content]"></ng-content>
            <div class="relative flex flex-col text-white">
                <div class="absolute bottom-8 left-0 justify-center items-center hidden w-full p-4 lg:flex flex-col">
                    <div class="flex items-center justify-center">
                        <div class="flex space-x-2 py-2.5 px-3 rounded-full bg-white dark:bg-white/[0.1]">
                          <!-- <div class="w-2 h-2 rounded-full bg-dark-blue dark:bg-white"></div> -->
                          <div 
                            *ngFor="let step of steps; let index = index" 
                            class="w-2 h-2 rounded-full cursor-pointer"
                            [ngClass]="{
                              'animate__animated animate__bounce bg-dark-blue dark:bg-white': currentStep === index,
                              'bg-dark-blue/[.30]': currentStep !== index
                            }"
                            [routerLink]="step"
                          ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   </div>
</div>