import { Component, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { FormDetails } from 'src/app/shared/models/shared/form/form-details';
import { FormValue } from 'src/app/shared/models/shared/form/form-value';
import { InviteMembersFormDetails } from 'src/assets/static-forms/auth/invite-org-members-form';

@Component({
  selector: 'hg-invite-members',
  templateUrl: './invite-members.component.html',
  styleUrls: ['./invite-members.component.scss']
})
export class InviteMembersComponent {
  inviteMembersFormDetails: FormDetails = InviteMembersFormDetails;

  constructor(private router: Router, private renderer: Renderer2){}

  sendUserInvites():void {
    if (this.inviteMembersFormDetails.fields.at(0)?.value.length) {
      this.router.navigate(['/auth/onboarding-complete']);
    }
    
  }
}
