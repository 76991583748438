import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { AuthModule } from './modules/auth/auth.module';
import { PreLaunchModule } from './modules/pre-launch/pre-launch.module';

const routes: Routes = [
 {
  path: '',
  loadChildren: () => import('./modules/pre-launch/pre-launch.module').then(m => PreLaunchModule)
 },
 {
  path: 'auth',
  loadChildren: () => import('./modules/auth/auth.module').then(m => AuthModule)
 },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules, 
    useHash: false, 
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
