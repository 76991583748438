import { Component, Input } from '@angular/core';
import { FormField } from 'src/app/shared/models/shared/form/form-field';

@Component({
  selector: 'hg-input-multi-select',
  templateUrl: './input-multi-select.component.html',
  styleUrls: ['./input-multi-select.component.scss']
})
export class InputMultiSelectComponent {
  @Input() fieldDetails!: FormField;

  validateInput(): void{
    setTimeout(() => {
      const value = this.fieldDetails.text?.split(',');
      this.fieldDetails.value = [];
      this.fieldDetails.customError = null;

      if ((value as []).length > 1) {
        value?.forEach((item) => {
          if (new RegExp(this.fieldDetails.pattern as string).test(item.trim())) {
            console.log(this.fieldDetails.value, this.fieldDetails.value.includes(item.trim()))
            !this.fieldDetails.value.includes(item.trim()) && this.fieldDetails.value.push(item.trim());
          } else if (item.trim().length > 0) {
            this.fieldDetails.customError = `One of the emails entered (${item}) is incorrect`;
          }
        })
      }
    }, 100);
  }

  resetField(): void {
    this.fieldDetails.cleared = false;
  }
}
