import { Component } from '@angular/core';

@Component({
  selector: 'hg-onboarding-completion',
  templateUrl: './onboarding-completion.component.html',
  styleUrls: ['./onboarding-completion.component.scss']
})
export class OnboardingCompletionComponent {

}
