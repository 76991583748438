<nav class="fixed z-50 w-full bg-auth dark:bg-gradient-to-r to-gradient-b-end from-gradient-b-start text-white max-h-32">
    <div class="px-6 pt-4 pb-3">
      <div class="flex items-center justify-between">
        <div class="flex items-center justify-start">
          <a class="flex justify-center">
            <hg-logo [themeToggle]="true" [darkMode]="false" class="block w-full h-[3rem] lg:h-[5rem]"></hg-logo>
          </a>
        </div>
        <div class="flex items-center justify-end">
            <a routerLink="/" class="flex justify-center cursor-pointer">
                <hg-icon [themeToggle]="true" variant="light" type="dark" name="remove" class="block h-[3rem] lg:h-[4rem] mb-5"></hg-icon>
            </a>
        </div>
        
      </div>
    </div>
</nav>
<div class="flex pt-24 sm:pt-28 overflow-hidden w-full bg-auth dark:bg-gradient-to-r to-gradient-b-end from-gradient-b-start">
    <aside id="sidebar" class="fixed top-0 left-0 z-20 flex flex-col flex-shrink-0 hidden w-56 h-full pt-24 font-normal duration-75 lg:flex transition-width" aria-label="Sidebar">
        <div class="relative flex flex-col flex-1 min-h-0 pt-0 bg-blend-color dark:bg-gradient-b-start/[0.05] bg-auth text-white">
            <div class="absolute bottom-0 left-0 justify-start hidden w-full p-4 lg:flex flex-col" sidebar-bottom-menu="">
                <hg-button *ngIf="!darkMode" class="text-dark-blue" type="basic" icon="half_moon" label="Dark Mode" [iconColor]="'dark'" (click)="toggleDarkMode()"></hg-button>
                <hg-button *ngIf="darkMode" class="text-white" type="basic" icon="sun" label="Light Mode" [iconColor]="'light'" (click)="toggleDarkMode()"></hg-button>
                <span class="text-start ml-1 p-2 rounded text-dark-blue dark:text-white">
                    &copy; <span class="ms-3"> 2023 Tateyru </span>
                </span>
            </div>
        </div>
    </aside>
    <div id="main-content" class="relative w-full h-full sm:z-30 rounded-tl-3xl overflow-hidden lg:ml-56">
        <main class="lg:border rounded-tl-3xl border-dark-blue bg-white/[0.35] dark:border-white/[0.22] dark:bg-[#979797]/[0.15] h-auto">
            <div class="absolute hidden lg:block inset-x-0 z-10 hover:z-0 transform-gpu blur-3xl overflow-hidden top-[calc(100%-25rem)] sm:top-[calc(100%-65rem)]" aria-hidden="true">
                <div class="relative animate overlay-shape opacity-30 aspect-[1155/678] bg-[#FC77FF] rounded-3xl sm:w-[31.125rem] sm:h-[21.5325rem]"></div>
            </div>
            <div class="z-30 relative h-fit sm:h-auto min-h-screen">
                <router-outlet></router-outlet>
            </div>
            <div class="absolute blur-3xl hover:hidden inset-x-0 z-10 transform-gpu overflow-hidden hidden lg:block sm:top-[calc(100%-20rem)] min-[1400px]:top-[calc(100%-12rem)]" aria-hidden="true">
                <div class="reverse-animate relative overlay-shape opacity-20 aspect-[1155/678] bg-[#0F73ED] rounded-3xl sm:w-[31.125rem] sm:h-[21.5325rem] rotate-[-26.43deg] min-[1400px]:w-[36.125rem] min-[1400px]:h-[26.5325rem] left-[calc(50%-15rem)] lg:left-[calc(50%-45rem)]"></div>
            </div>
        </main>
    </div>
</div>