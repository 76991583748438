import { createAction, props } from '@ngrx/store';


export const toggleDarkMode = createAction('ToggleDarkMode',
  props<{ darkMode: boolean }>()
);

export const setAuthDetails = createAction('SetAuthDetails', 
  props<{ authDetails: {
    email: string,
    type: string
  } }>()
)
