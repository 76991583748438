import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormDetails } from 'src/app/shared/models/shared/form/form-details';
import { FormField } from 'src/app/shared/models/shared/form/form-field';
import { FormValue } from 'src/app/shared/models/shared/form/form-value';

@Component({
  selector: 'hg-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent {
  @Input() formDetails!: FormDetails;
  @Output() form = new EventEmitter<FormValue>();

  async submitForm(form: NgForm): Promise<void> {
    const formValue = this.getFormValues();
    await this.form.emit(formValue);
  }

  getFormValues(): FormValue {
    const value = this.formDetails.fields.map((field: FormField) => {
      return {
        name: field.name,
        value: field.value
      }
    })
    return value;
  }

  async resetForm() {
    this.formDetails.fields.forEach((field: FormField) => {
      field.value = '';
      field.cleared = true;
    });
  }
}
