import { Component, Input } from '@angular/core';
import { FormField } from 'src/app/shared/models/shared/form/form-field';

@Component({
  selector: 'hg-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss']
})
export class TextAreaComponent {
  @Input() fieldDetails!: FormField;

  resetField(): void {
    this.fieldDetails.cleared = false;
  }
}
