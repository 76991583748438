<form 
  *ngIf="formDetails"
  #defaultForm="ngForm"
  (submit)="submitForm(defaultForm)"
  ngNativeValidate
  class="flex flex-col items-start"
  [ngClass]="{
    'sm:flex-row sm:items-center sm:justify-between': formDetails.fieldOrientation === 'row'
  }"
>
    <div 
      *ngIf="formDetails.displayFields" 
      class="flex-auto w-full"
    >
      <div *ngFor="let field of formDetails.fields" class="my-6">
  
        <div [ngSwitch]="field.fieldType">
  
          <div *ngSwitchCase="'input'">
            <hg-input [fieldDetails]="field"></hg-input>
          </div>

          <div *ngSwitchCase="'input-multi-select'">
            <hg-input-multi-select [fieldDetails]="field"></hg-input-multi-select>
          </div>

          <div *ngSwitchCase="'input-group'"> 
            <hg-input-group [fieldDetails]="field"></hg-input-group>
          </div>

          <div *ngSwitchCase="'input-file-upload'"> 
            <hg-input-file-upload [fieldDetails]="field"></hg-input-file-upload>
          </div>

          <div *ngSwitchCase="'select'"> 
            <hg-select [fieldDetails]="field"></hg-select>
          </div>

          <div *ngSwitchCase="'pin-input'" class="max-w-full"> 
            <hg-pin-input [fieldDetails]="field"></hg-pin-input>
          </div>

          <div *ngSwitchCase="'text-area'"> 
            <hg-text-area [fieldDetails]="field"></hg-text-area>
          </div>
  
        </div>
      </div>
    </div>
    <div 
      class="flex flex-col flex-1 w-full"
      [ngClass]="{'sm:mx-2 sm:flex-row sm:my-0 my-5': formDetails.buttonOrientation === 'row' }"
    >
        <hg-button 
          *ngFor="let button of formDetails.buttons" 
          [label]="button.label" 
          [type]="button.type"
          class="w-full"
          [ngClass]="{'my-0 sm:my-6': formDetails.buttonOrientation === 'row' }"
        >
        </hg-button>
        <ng-content select="[formButtonSlot]"></ng-content>
    </div>
  </form>
  