<hg-auth-wizard-item [currentStep]="2">
    <span title>
        Invite co-workers to your team
    </span>
    <span subtitle>            
        Hagile is meant to be used with your team. Invite some co-workers to test it out with.
    </span>
    <div options class="sm:border border-greyish-blue rounded py-6 sm:px-24 mt-6 w-full sm:w-9/12">
        <hg-form [formDetails]="inviteMembersFormDetails">
            <div formButtonSlot class="w-full">
                <hg-button class="hidden" [docked]="true" type="primary" label="Create Organization"></hg-button>
            </div>
        </hg-form>
    </div>
    <div content>
        <hg-button (click)="sendUserInvites()" [docked]="true" label="Send Invites" class="btn-available-width"></hg-button>
        <hg-button routerLink="/auth/onboarding-complete" [docked]="true" type="transparent" label="I'll do this later" class="block mt-6"></hg-button>
    </div>
</hg-auth-wizard-item>