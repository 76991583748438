import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth.component';
import { CreateOrganizationComponent } from './pages/onboarding/create-organization/create-organization.component';
import { EmailCodeCheckComponent } from './pages/onboarding/email-code-check/email-code-check.component';
import { InviteMembersComponent } from './pages/onboarding/invite-members/invite-members.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { ThemeSelectionComponent } from './pages/onboarding/theme-selection/theme-selection.component';
import { WelcomePageComponent } from './pages/onboarding/welcome-page/welcome-page.component';
import { OnboardingCompletionComponent } from './pages/onboarding/onboarding-completion/onboarding-completion.component';

const routes: Routes = [
  {
    path: '', component: AuthComponent, children: [
      {
        path: '', redirectTo: 'login', pathMatch: 'full'
      },
      {
        path: 'login', component: SignInComponent
      },
      {
        path: 'signup', component: SignUpComponent
      },
      {
        path: ':type/code', component: EmailCodeCheckComponent
      },
      {
        path: 'create-organization', component: CreateOrganizationComponent
      },
      {
        path: 'welcome', component: WelcomePageComponent
      },
      {
        path: 'theme-selection', component: ThemeSelectionComponent
      },
      {
        path: 'invite-members', component: InviteMembersComponent
      },
      {
        path: 'onboarding-complete', component: OnboardingCompletionComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
