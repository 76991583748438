<div class="relative">
    <div 
        class="space-y-6"
    >
        <div class="relative">
            <input 
                #ctrl="ngModel"
                [id]="fieldDetails.name" 
                [type]="fieldDetails.type" 
                [(ngModel)]="fieldDetails.text"
                placeholder="{{fieldDetails.placeholder}}"
                autocomplete="{{fieldDetails.autocomplete || false}}"
                disabled="{{fieldDetails.disabled || false}}"
                required="{{fieldDetails.required || false}}"
                (input)="validateInput(); resetField()"
                class="peer rounded-xl placeholder-transparent py-5 px-8 w-full border bg-transparent text-dark-blue dark:text-whitish-blue" 
                [ngClass]="{
                    'border-grey-600': !ctrl.touched && !ctrl.dirty,
                    'border-error mb-6': fieldDetails.customError,
                    'border-success': ctrl.touched && ctrl.dirty && !ctrl.errors && !fieldDetails.customError && !fieldDetails.customError
                }"
            />
            <label 
                for="{{fieldDetails.name}}" 
                class="absolute truncate w-72 min-[1440px]:w-fit min-[1440px]:text-start left-8 -top-2.5 py-1 px-2 rounded-lg text-sm transition-all bg-white text-gradient-b-end dark:text-greyish-blue peer-placeholder-shown:p-0 peer-placeholder-shown:bg-transparent peer-placeholder-shown:top-5 peer-placeholder-shown:text-base peer-focus:-top-3 peer-focus:bg-white peer-focus:text-sm peer-focus:px-2 peer-focus:py-1 peer-focus:rounded-lg dark:peer-placeholder-shown:text-greyish-blue/[0.5] peer-placeholder-shown:text-dark-blue/[0.5] peer-focus:text-gradient-b-end dark:peer-focus:text-greyish-blue">
                {{fieldDetails.label}}
            </label>
            <div 
                *ngIf="!fieldDetails.cleared && fieldDetails.customError" 
                class="absolute left-0 top-20 text-error text-sm transition-all"
                >
                <div *ngIf="ctrl.errors && !fieldDetails.customError">
                    <div *ngIf="ctrl.errors['required']">
                    <hg-icon name="warning" type="error" size="sm" class="align-text-bottom"></hg-icon>
                    This field is required
                    </div>
                    <div *ngIf="ctrl.errors['pattern']">
                    <hg-icon name="warning" type="error" size="sm" class="align-text-bottom"></hg-icon>
                    Invalid input.
                    </div>
                </div>
                <div *ngIf="fieldDetails.customError">
                    <hg-icon name="warning" type="error" size="sm" class="align-text-bottom"></hg-icon>
                    {{ fieldDetails.customError }}
                </div>
            </div>
        </div>
        <div class="relative">
            <div 
                class="peer items-start flex flex-wrap rounded-xl placeholder-transparent py-6 px-8 w-full border bg-transparent text-dark-blue dark:text-whitish-blue" 
                [ngClass]="{
                    'border-grey-600': !ctrl.touched && !ctrl.dirty,
                    'border-error mt-8': fieldDetails.customError ,
                    'border-success': ctrl.touched && ctrl.dirty && !ctrl.errors && !fieldDetails.customError && !fieldDetails.customError
                }"
            >
                <label 
                    for="{{fieldDetails.name}}" 
                    class="p-2 me-3 mt-1 rounded-lg text-sm transition-all bg-greyish-blue/[0.1] text-dark-blue dark:text-whitish-blue"
                    *ngFor="let value of fieldDetails.value"
                >
                    {{value}}
                </label>
                <span 
                    class="text-dark-blue/[0.5] dark:text-whitish-blue/[0.5]"
                    *ngIf="!fieldDetails.value?.length"
                > 
                    Team Members will appear here
                </span>
            </div>
            <label 
                class="absolute left-8 -top-2.5 py-1 px-2 rounded-lg text-sm transition-all bg-white text-gradient-b-end dark:text-greyish-blue peer-placeholder-shown:p-0 peer-placeholder-shown:bg-transparent peer-placeholder-shown:top-5 peer-placeholder-shown:text-base peer-focus:-top-3 peer-focus:bg-white peer-focus:text-sm peer-focus:px-2 peer-focus:py-1 peer-focus:rounded-lg dark:peer-placeholder-shown:text-greyish-blue/[0.5] peer-placeholder-shown:text-dark-blue/[0.5] peer-focus:text-gradient-b-end dark:peer-focus:text-greyish-blue">
                {{fieldDetails.subText}}
            </label>
        </div>
    </div>
</div>