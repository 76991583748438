import { Component } from '@angular/core';

@Component({
  selector: 'hg-base-header',
  templateUrl: './base-header.component.html',
  styleUrls: ['./base-header.component.scss']
})
export class BaseHeaderComponent {
  mobileMenuOpen: boolean = false;
  hasMobileMenu: boolean = false;
  darkMode: boolean = false;

  toggleMenu(): void {
    this.mobileMenuOpen = !this.mobileMenuOpen;
  }
}
