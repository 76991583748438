import { Component, Input } from '@angular/core';
import { FormField } from 'src/app/shared/models/shared/form/form-field';

@Component({
  selector: 'hg-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent {
  @Input() fieldDetails!: FormField;

  resetField(): void {
    this.fieldDetails.cleared = false;
  }
}
