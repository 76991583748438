import { ActionReducerMap, MetaReducer } from "@ngrx/store";
import { StateDetails } from "../models/state-management/state";
import { appReducer } from "./reducers/app.reducer";
import { rehydrateMetaReducer } from "./reducers/metaReducer.reducer";


export interface RootState {
    stateReducer: StateDetails;
}

export const reducers: ActionReducerMap<RootState> = {
    stateReducer: appReducer
}

export const metaReducers: MetaReducer[] = [
    rehydrateMetaReducer
]