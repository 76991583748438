import { Component, Input } from '@angular/core';

@Component({
  selector: 'hg-marquee',
  templateUrl: './marquee.component.html',
  styleUrls: ['./marquee.component.scss']
})
export class MarqueeComponent {
  @Input() darkMode: boolean = true;
  @Input() iconColor: 'light' | 'regular' | 'dark' | 'grey' | 'violet' = 'regular';
}
