import { Component, Input } from '@angular/core';
import { FormField } from 'src/app/shared/models/shared/form/form-field';

@Component({
  selector: 'hg-input-group',
  templateUrl: './input-group.component.html',
  styleUrls: ['./input-group.component.scss']
})
export class InputGroupComponent {
  @Input() fieldDetails!: FormField;

  resetField(): void {
    this.fieldDetails.cleared = false;
  }
}
