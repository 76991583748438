import { Component } from '@angular/core';

@Component({
  selector: 'hg-pre-launch-header',
  templateUrl: './pre-launch-header.component.html',
  styleUrls: ['./pre-launch-header.component.scss']
})
export class PreLaunchHeaderComponent {
  showSubMenu: { [key: number]: boolean } = {};

  toggleSubMenu(id: number): void {
    this.showSubMenu[id] = !this.showSubMenu[id]
  }
}
