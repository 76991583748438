import { FormDetails } from "src/app/shared/models/shared/form/form-details";

export const InviteMembersFormDetails: FormDetails = {
    name: 'create-organization',
    fieldOrientation: 'column',
    buttonOrientation: 'column',
    displayFields: true,
    fields: [
        {
            fieldType: 'input-multi-select',
            type: 'text',
            name: 'users',
            hasLabel: true, 
            placeholder: 'Add emails (johndoe@company.com, james@company.com)',
            label: 'Add emails (johndoe@company.com, james@company.com)',
            subText: 'Your team',
            required: true, 
            disabled: false,
            minLength: 1,
            value: [],
            pattern: '^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$'
        }
    ]
}