import { Component } from '@angular/core';

@Component({
  selector: 'hg-public-header',
  templateUrl: './public-header.component.html',
  styleUrls: ['./public-header.component.scss']
})
export class PublicHeaderComponent {
  showSubMenu: { [key: number]: boolean } = {};

  toggleSubMenu(id: number): void {
    this.showSubMenu[id] = !this.showSubMenu[id]
  }
}
