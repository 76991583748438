<div class="mt-20 space-y-6 pb-5 sm:pb-0">
    <div> 
        <hg-icon name="lock" type="grey"></hg-icon> 
        <span class="align-sub ml-2.5">Connection Secured </span>
    </div>
    <div>
        <hg-button (click)="handleGoogleAuth()" class="w-full" [iconPosition]="'left'" [docked]="true" type="primary" icon="google_s" [iconColor]="'light'" label="Continue with Google"></hg-button>
    </div>
    <hr class="border-t-2 border-dashed border-greyish-blue space-y-6">
    <div>
        <hg-form [formDetails]="emailAuthFormDetails" (form)="handleEmailAuth($event)">
            <div formButtonSlot class="w-full">
                <hg-button class="w-full" [iconPosition]="'left'" [docked]="true" type="primary" icon="gmail_b" [iconColor]="'light'" label="Continue with Email" (click)="submitButtonClick(emailAuthFormDetails)" [ngClass]="{'animate__animated animate__fadeInDown': emailAuthFormDetails.displayFields}"></hg-button>
            </div>
        </hg-form>
    </div>
    <hr class="border-t-2 border-dashed border-greyish-blue">
    <div>
        <hg-form [formDetails]="samlAuthFormDetails" (form)="handleSamlAuth($event)">
            <div formButtonSlot class="w-full">
                <hg-button class="w-full" [iconPosition]="'left'" [docked]="true" type="primary" icon="mail_block_b" [iconColor]="'light'" label="Continue with SAML SSO" (click)="submitButtonClick(samlAuthFormDetails)" [ngClass]="{'animate__animated animate__fadeInDown': samlAuthFormDetails.displayFields}"></hg-button>
            </div>
        </hg-form>
    </div>
    <div class="text-lg tracking-wide"> 
        By registering, you accept our 
        <a class="text-violet underline underline-offset-8">Terms of use</a> and 
        <a class="text-violet underline underline-offset-8"> Privacy Policy </a>
    </div>
</div>