import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IconSize, IconType } from 'src/app/shared/models/shared/icon/icon-details';
import { StateDetails } from 'src/app/shared/models/state-management/state';
import { ICONS, ICONSIZES, ICONCOLORS } from "src/assets/app-constants/icons";

@Component({
  selector: 'hg-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() name!: string;
  @Input() size!: IconSize;
  @Input() themeToggle: boolean = false;
  @Input() type: IconType = 'regular';
  @Input() variant: IconType = 'light';
  @Input() alignToText: boolean = false;
  iconSrc!: string;
  iconSize!: string;
  iconColor!: string;
  iconReady: boolean = false;
  appState$!: Observable<StateDetails>;

  constructor(
    private elementRef: ElementRef, 
    private sanitize: DomSanitizer,
    private store: Store<{stateReducer: StateDetails}>
  ){}

  ngOnChanges(changes: SimpleChanges): void {
    this.iconSrc = this.sanitize.bypassSecurityTrustResourceUrl(`${ICONS[this.name]}`) as string;
    this.iconSize = ICONSIZES[this.size];
  }

  ngOnInit(): void {
    this.iconSrc = this.sanitize.bypassSecurityTrustResourceUrl(`${ICONS[this.name]}`) as string;
    this.iconSize = ICONSIZES[this.size];

    if (this.type !== 'regular') {
      this.iconColor = ICONCOLORS[this.type];
    }

    if (this.themeToggle) {
      this.handleThemeToggle();
    }
  }

  async ngAfterViewInit(): Promise<void> {
    const svgObject = this.elementRef.nativeElement.querySelector('#svgObject');

    try {
      await svgObject.addEventListener('load', async () => {
        const svgDoc = svgObject.contentDocument;
        const svg = svgDoc.querySelector('svg');   
        if (this.iconSize) {
          svgObject.setAttribute('height', (parseInt(this.iconSize) * 16));
          svgObject.setAttribute('width', (parseInt(this.iconSize) * 16));
          svg.setAttribute('height', (parseInt(this.iconSize) * 16));
          svg.setAttribute('width', (parseInt(this.iconSize) * 16));
        }    

        this.iconReady = true;
        if (svgDoc && this.iconColor) {
          const path = svgDoc.querySelectorAll('*');
          if (path) {
            await path.forEach((element: HTMLElement) => {
              element.setAttribute('stroke', this.iconColor);
              if (this.name.includes('_')) {
                element.setAttribute('fill', this.iconColor);
              }
            });
          }
        }
      });
    } catch (err) {
      console.error(err);
    }

  }

  async toggleIconColor(): Promise<void> {
    const iconSvg = this.elementRef.nativeElement.querySelector('#svgObject').contentDocument?.querySelector('svg');
    const path = iconSvg?.querySelectorAll('*');
    
    if (path) {
      path.forEach((element: HTMLElement) => {
        element.setAttribute('stroke', this.iconColor);
        if (this.name.includes('_')) {
          element.setAttribute('fill', this.iconColor);
        }
      });
    }
  }

  handleThemeToggle(): void {
    this.appState$ = this.store.select('stateReducer');
      this.appState$.subscribe(
        res => {
           (res.darkMode && this.variant) ? 
            this.iconColor = ICONCOLORS[this.variant] : 
            this.iconColor = ICONCOLORS[this.type]; 

            this.toggleIconColor();
        }
      )
  }
}
