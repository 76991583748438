import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store } from '@ngrx/store';
import { OAuthService, AuthConfig } from 'angular-oauth2-oidc';
import { StateDetails } from 'src/app/shared/models/state-management/state';
import { setAuthDetails } from 'src/app/shared/state-management/actions/app.actions';

@Injectable({
  providedIn: 'root',
})
export class GoogleAuthService {
  private claims: any;

  constructor(
    private oauthService: OAuthService,
    private store: Store<{ stateReducer: StateDetails }>
  ) {}

  setupGoogleAuth(authType: string): void {
    const authCodeFlowConfig: AuthConfig = {
      // Url of the Identity Provider
      issuer: 'https://accounts.google.com',
      // URL of the SPA to redirect the user to after login
      redirectUri: `${window.location.origin}/auth/${authType}/code`,
      // The SPA's id. The SPA is registerd with this id at the auth-server
      // clientId: 'server.code',
      clientId:
        '126576617935-gqe0djnct1fqdut96e6p3irp8b756gds.apps.googleusercontent.com',
      // Just needed if your auth server demands a secret. In general, this
      // is a sign that the auth server is not configured with SPAs in mind
      // and it might not enforce further best practices vital for security
      // such applications.
      // dummyClientSecret: 'secret',
      responseType: 'token id_token',
      // set the scope for the permissions the client should request
      // The first four are defined by OIDC.
      // Important: Request offline_access to get a refresh token
      // The api scope is a usecase specific one
      scope: 'openid profile email',
      showDebugInformation: true,
      strictDiscoveryDocumentValidation: false,
    };
    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.loadDiscoveryDocumentAndTryLogin();
  }

  login(): void {
    this.isAuthenticated && this.logOut();
    this.oauthService.initCodeFlow();
  }

  logOut(): void {
    this.oauthService.revokeTokenAndLogout();
  }

  updateAuthDetails(id_token: string) {
    const jwtHelperService = new JwtHelperService();
    this.claims = jwtHelperService.decodeToken(id_token);
    const authDetails = { email: this.claims.email, type: 'social' };
    this.store.dispatch(setAuthDetails({ authDetails }));
  }

  get token() {
    return this.oauthService.getIdToken();
  }

  get email() {
    const claims = this.oauthService.getIdentityClaims();
    return claims ? claims['email'] : '';
  }

  get userName() {
    const claims = this.oauthService.getIdentityClaims();
    return claims ? claims['preferred_username'] : '';
  }

  get isAuthenticated() {
    return this.oauthService.hasValidAccessToken();
  }
}
