import { Component } from '@angular/core';
import { Store } from "@ngrx/store";
import { Observable } from 'rxjs';
import { StateDetails } from 'src/app/shared/models/state-management/state';
import { toggleDarkMode } from "src/app/shared/state-management/actions/app.actions";
@Component({
  selector: 'hg-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent {
  darkMode: boolean = false;
  appState$: Observable<StateDetails>

  constructor(
    private store: Store<{stateReducer: StateDetails}>
  ) {
    this.appState$ = this.store.select('stateReducer');
    this.appState$.subscribe(
      res => {
        this.darkMode = res.darkMode as boolean;
      }
    )
  }

  toggleDarkMode(): void {
    this.darkMode = !this.darkMode
    this.store.dispatch(toggleDarkMode({darkMode: this.darkMode}));
  }

}
