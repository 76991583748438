<hg-auth-page-return>
    <span pageTitle>
        Check your email
    </span>
    <span pageSubTitle>
        We've sent a temporary login link. <br> 
        Please check your inbox at 
        <span class="text-violet underline underline-offset-8"> 
            {{ authDetails.email }} 
        </span>
    </span>
    <div class="mt-20 space-y-6" pageContent>
        <div>
            <hg-form *ngIf="manualAuth" [formDetails]="codeLoginFormDetails" (form)="handleSubmit($event)" class="animate__animated animate__fadeInDown">
                <div formButtonSlot class="w-full">
                    <hg-button class="w-full" [iconPosition]="'left'" [docked]="true" type="primary" icon="lock_b" [iconColor]="'light'" label="Continue with login code"></hg-button>
                </div>
            </hg-form>
            <hg-button 
                *ngIf="!manualAuth" 
                class="w-full" 
                [iconPosition]="'left'" 
                [docked]="true" 
                type="primary" 
                icon="lock_b" 
                [iconColor]="'light'" 
                label="Enter code manually" 
                (click)="toggleManualAuth()"
            ></hg-button>
        </div>
        <div>
            <hg-button routerLink="/auth" class="w-full" [iconPosition]="'left'" [docked]="true" type="transparent" icon="arrow_left_circle_b" [iconColor]="'dark'" [iconColorVariant]="'light'" label="Back to {{authType}}"></hg-button>
        </div>
    </div>
</hg-auth-page-return>