import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { OAuthModule } from 'angular-oauth2-oidc';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { StoreModule } from '@ngrx/store';

import { reducers, metaReducers } from './shared/state-management';
import { HttpClientModule } from '@angular/common/http';


// Note we need a separate function for lottie as it's required by the AOT compiler.
export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    LottieModule.forRoot({ player: playerFactory }),
    LottieCacheModule.forRoot(),
    StoreModule.forRoot(reducers, {metaReducers}),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: ['https://www.googleapis.com/oauth2/v3/userinfo'],
        sendAccessToken: true
      }
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
