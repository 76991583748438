<div [ngClass]="{
    'min-[1400px]:top-[calc(100%-45rem)] min-[1450px]:top-[calc(100%-50rem)]': rowOriented, 
    'lg:top-[calc(100%-45rem)] min-[1400px]:top-[calc(100%-55rem)]': !rowOriented}" 
    class="absolute inset-x-0 -z-10 transform-gpu blur-3xl overflow-hidden top-[calc(100%-25rem)] sm:top-[calc(100%-40rem)]" 
    aria-hidden="true">
    <div [ngClass]="{
        'rotate-[-26.43deg] min-[1400px]:w-[36.125rem] min-[1400px]:h-[26.5325rem] sm:left-[calc(100%-40rem)] lg:left-[calc(50%+0rem)]': rowOriented,
        'rotate-[-153.43deg] min-[1400px]:w-[48rem] min-[1400px]:h-[38rem] lg:left-[calc(50%-35rem)] min-[1440px]:left-[calc(50%-50rem)]': !rowOriented}" 
        class="relative overlay-shape opacity-30 aspect-[1155/678] bg-[#FC77FF] rounded-3xl sm:w-[31.125rem] sm:h-[21.5325rem]">
    </div>
</div>