import { Component, Input } from '@angular/core';

@Component({
  selector: 'hg-hero-blur-underlay',
  templateUrl: './hero-blur-underlay.component.html',
  styleUrls: ['./hero-blur-underlay.component.scss']
})
export class HeroBlurUnderlayComponent {
  @Input() rowOriented: boolean = true;
  @Input() darkMode: boolean = false;
}
