<button 
    [ngClass]="{
        'items-center': type !== 'basic', 
        'item-start py-2 px-2': type === 'basic',
        'flex': !docked,
        'flex justify-center': docked
    }" 
    type="submit"
    class="rounded-full text-base {{buttonClasses}}"
> 

<hg-icon *ngIf="icon" [themeToggle]="true" [name]="icon" [size]="size" [type]="iconColor" [variant]="iconColorVariant" [alignToText]="true"></hg-icon>
<span *ngIf="label" [ngClass]="{'ml-2': iconPosition === 'left', 'mr-2': iconPosition === 'right'}">
    {{label}}
</span>
</button>