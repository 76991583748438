import { IconMap } from "src/app/shared/models/shared/icon/icon-details"

export const ICONSIZES: IconMap = {
    sm: '1.125',    
    md: '1.25',
    lg: '1.325',
    xl: '1.5',
    marquee: '9.5'
}

export const ICONCOLORS: IconMap = {
    light: '#FFFFFF',
    dark: '#07072B',
    grey: '#6E80A3',
    violet: '#9453FF',
    error: '#FF5C5C',
}

export const ICONS: IconMap = {
    // TODO:
    // Outline Icons
    //Solid Icons
    //Bulk Icons
    hagile: 'assets/icons/outline/hagile.svg',
    warning: 'assets/icons/outline/warning.svg',
    warning_b: 'assets/icons/bulk/warning.svg',
    warning_s: 'assets/icons/solid/warning.svg',
    briefcase: 'assets/icons/outline/briefcase.svg',
    briefcase_b: 'assets/icons/bulk/briefcase.svg',
    briefcase_s: 'assets/icons/solid/briefcase.svg',
    arrow_left_circle: 'assets/icons/outline/arrow-left-circle.svg',
    arrow_left_circle_b: 'assets/icons/bulk/arrow-left-circle.svg',
    arrow_left_circle_s: 'assets/icons/solid/arrow-left-circle.svg',
    gmail: 'assets/icons/outline/gmail.svg',
    gmail_b: 'assets/icons/bulk/gmail.svg',
    gmail_s: 'assets/icons/solid/gmail.svg',
    google: 'assets/icons/outline/google.svg',
    google_b: 'assets/icons/bulk/google.svg',
    google_s: 'assets/icons/solid/google.svg',
    mail_block: 'assets/icons/outline/mail block.svg',
    mail_block_b: 'assets/icons/bulk/mail block.svg',
    mail_block_s: 'assets/icons/solid/mail block.svg',
    lock: 'assets/icons/outline/lock.svg',
    lock_b: 'assets/icons/bulk/lock.svg',
    lock_s: 'assets/icons/solid/lock.svg',
    half_moon: 'assets/icons/outline/half moon.svg',
    half_moon_b: 'assets/icons/bulk/half moon.svg',
    half_moon_s: 'assets/icons/solid/half moon.svg',
    remove: 'assets/icons/outline/remove.svg',
    remove_b: 'assets/icons/bulk/remove.svg',
    remove_s: 'assets/icons/solid/remove.svg',
    sun: 'assets/icons/outline/sun.svg',
    sun_b: 'assets/icons/bulk/sun.svg',
    sun_s: 'assets/icons/solid/sun.svg',
    taskcheck: 'assets/icons/outline/task-check.svg',
    taskcheck_b: 'assets/icons/bulk/task-check.svg',
    taskcheck_s: 'assets/icons/solid/task-check.svg',
    arrow_right: 'assets/icons/outline/arrow-right.svg',
    arrow_right_b: 'assets/icons/bulk/arrow-right.svg',
    arrow_right_s: 'assets/icons/solid/arrow-right.svg',
    twitter: 'assets/icons/outline/twitter.svg',
    twitter_b: 'assets/icons/bulk/twitter.svg',
    twitter_s: 'assets/icons/solid/twitter.svg',
    instagram: 'assets/icons/outline/instagram.svg',
    instagram_b: 'assets/icons/bulk/instagram.svg',
    instagram_s: 'assets/icons/solid/instagram.svg',
    github: 'assets/icons/solid/github.svg',
}