<hg-auth-wizard-item [currentStep]="1">
    <span title>
        Choose your theme
    </span>
    <span subtitle>            
        You can always change your UI theme through preference in the menu    
    </span>
    <div options class="flex flex-col sm:flex-row">
        <div [ngClass]="{'bg-dark-blue text-white': darkMode, 'border-dashed text-dark-blue dark:text-white': !darkMode}" 
            (click)="toggleDarkMode(true)" 
            class="px-14 py-9 cursor-pointer border-greyish-blue border rounded-s-lg text-center"
        >
                <img class="max-w-none mb-3" src="assets/images/vectors/dark-theme-vector.svg">
                <span> Dark Theme </span>
        </div>
        <div 
            [ngClass]="{'bg-dark-blue text-white': !darkMode, 'border-dashed text-dark-blue dark:text-white': darkMode}"
            (click)="toggleDarkMode(false)" 
            class="px-14 py-9 cursor-pointer mt-2 sm:mt-0 sm:border-s-0 border border-greyish-blue rounded-e-lg bg-dark-blue text-white text-center"
        >
            <img class="max-w-none mb-3" src="assets/images/vectors/light-theme-vector.svg">
            <span> Light Theme </span>
        </div>
    </div>
    <span content>
        <hg-button routerLink="/auth/invite-members" [docked]="true" label="Continue"></hg-button>
    </span>
</hg-auth-wizard-item>