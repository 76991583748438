import { Component } from '@angular/core';

@Component({
  selector: 'hg-public-footer',
  templateUrl: './public-footer.component.html',
  styleUrls: ['./public-footer.component.scss']
})
export class PublicFooterComponent {

}
