import { FormDetails } from "src/app/shared/models/shared/form/form-details";

export const CreateOrgFormDetails: FormDetails = {
    name: 'create-organization',
    fieldOrientation: 'column',
    buttonOrientation: 'column',
    displayFields: true,
    fields: [
        {
            fieldType: 'input',
            type: 'text',
            name: 'name',
            hasLabel: true, 
            placeholder: 'Organization Name',
            label: 'Organization Name',
            required: true, 
            disabled: false,
            minLength: 1,
        },
        {
            fieldType: 'input',
            type: 'text',
            name: 'url',
            hasLabel: true, 
            placeholder: 'Organization URL (e.g hagile.com/company)',
            label: 'Organization URL',
            required: true, 
            disabled: false,
            minLength: 1,
        },
        {
            fieldType: 'select',
            type: 'select',
            name: 'size',
            hasLabel: true, 
            placeholder: 'Select company Size',
            label: 'Company Size',
            required: true, 
            disabled: false,
            value: '',
            options: [
                {
                    value: '1',
                    name: 'Just Me'
                },
                {
                    value: '1-5',
                    name: '1-5'
                },
                {
                    value: 'n/a',
                    name: 'Prefer not to share'
                }
            ]
        },
        {
            fieldType: 'select',
            type: 'select',
            name: 'size',
            hasLabel: true, 
            label: 'Your role in the company',
            placeholder: 'Select your role in the company',
            required: true, 
            disabled: false,
            value: '',
            options: [
                {
                    value: 'Founder',
                    name: 'Founder'
                },
                {
                    value: 'Engineering Manager',
                    name: 'Engineering Manager'
                },
                {
                    value: 'n/a',
                    name: 'Prefer not to share'
                }
            ]
        }
    ]
}