import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormDetails } from 'src/app/shared/models/shared/form/form-details';
import { AuthEmailOptionFormDetails, AuthSamlOptionFormDetails } from 'src/assets/static-forms/auth/auth-options-form';
import { GoogleAuthService } from "src/app/shared/services/auth/google-auth/google-auth.service";
import { FormValue } from 'src/app/shared/models/shared/form/form-value';
import { Observable } from 'rxjs';
import { StateDetails } from 'src/app/shared/models/state-management/state';
import { Store } from '@ngrx/store';
import { setAuthDetails } from 'src/app/shared/state-management/actions/app.actions';
@Component({
  selector: 'hg-auth-options',
  templateUrl: './auth-options.component.html',
  styleUrls: ['./auth-options.component.scss']
})
export class AuthOptionsComponent {
  emailAuthFormDetails: FormDetails = AuthEmailOptionFormDetails;
  samlAuthFormDetails: FormDetails = AuthSamlOptionFormDetails;
  appState$!: Observable<StateDetails>;
  authType!: string;

  constructor(
    private route: ActivatedRoute, 
    private router: Router, 
    private googleAuth: GoogleAuthService,
    private store: Store<{stateReducer: StateDetails}>) {
    this.route.url.subscribe((segment) => {
      this.authType = segment.at(0)?.path as string;
      this.googleAuth.setupGoogleAuth(this.authType);
    })
  }

  handleGoogleAuth(): void {
    this.storeAuthDetails('', 'social');
    this.googleAuth.login();
  }

  handleEmailAuth(value: FormValue): void {
    if (this.emailAuthFormDetails.displayFields) {
      this.storeAuthDetails(value.at(0)?.value as string, 'email');
      this.router.navigate([`/auth/${this.authType}/code`]);
    } 
  }

  handleSamlAuth(value: any): void {
    if (this.samlAuthFormDetails.displayFields) {
      this.storeAuthDetails(value.at(0)?.value as string, 'sso');
      this.router.navigate([`/auth/${this.authType}/code`]);
    } 
  }

  storeAuthDetails(email: string, type: string): void {
    const authDetails = {email, type};
    this.store.dispatch(setAuthDetails({authDetails}));
  }

  hideAllForms(): void {
    this.emailAuthFormDetails.displayFields = false;
    this.samlAuthFormDetails.displayFields = false;
  }

  submitButtonClick(formDetails: FormDetails): void {
    if (!formDetails.displayFields) {
      setTimeout(() => {
        this.hideAllForms();
        formDetails.displayFields = true;
        formDetails.displayButtons = true;
      }, 100)    
    }
  }
}
