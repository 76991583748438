import { Component, Input } from '@angular/core';
import { FormField } from 'src/app/shared/models/shared/form/form-field';

@Component({
  selector: 'hg-pin-input',
  templateUrl: './pin-input.component.html',
  styleUrls: ['./pin-input.component.scss']
})
export class PinInputComponent {
  @Input() fieldDetails!: FormField;
  pin: string[] = ['', '', '', '', '', ''];

  onInputChange(index: number) {
    if (!new RegExp(this.fieldDetails.pattern as string)?.test(this.pin[index])) {
      return this.setFocus(index);
    }

    if (index < 5 && this.pin[index].length === 1) {
      this.setFocus(index + 1);
    }

    if (index === this.pin.length - 1) {
      this.fieldDetails.value = this.pin.join('');
    }
  }

  setFocus(index: number) {
    const inputField = document.getElementById(`pin${index}`);
    if (inputField) {
      inputField.focus();
      (inputField as HTMLInputElement).value = '';
    }
  }

  onKeyDown(event: KeyboardEvent, index: number) {
    if (event.key === 'Backspace') {
      event.preventDefault(); // Prevent browser navigation
      this.pin[index] = '';

      if (index > 0) {
        this.setFocus(index - 1);
      }
    } 
  }

  resetField(): void {
    this.fieldDetails.cleared = false;
  }
}
