import { FormDetails } from "src/app/shared/models/shared/form/form-details";

export const CodeLoginFormDetails: FormDetails = {
    name: 'code-login',
    fieldOrientation: 'column',
    buttonOrientation: 'column',
    displayFields: true,
    fields: [
        {
            fieldType: 'pin-input',
            type: 'email',
            name: 'email',
            minLength: 1,
            maxLength: 1,
            required: true, 
            disabled: false,
            pattern: '^[0-9]$'
        }
    ]
}