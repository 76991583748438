import { Component } from '@angular/core';

@Component({
  selector: 'hg-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent {

}
