import { Component } from '@angular/core';

@Component({
  selector: 'hg-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent {

}
