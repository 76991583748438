import { FormDetails } from "src/app/shared/models/shared/form/form-details";

export const PreLaunchFormDetails: FormDetails = {
    name: 'prelaunchform',
    fieldOrientation: 'row',
    buttonOrientation: 'row',
    displayFields: true,
    fields: [
        {
            fieldType: 'input',
            type: 'email',
            name: 'email',
            hasLabel: true, 
            placeholder: 'Email',
            label: 'Email',
            minLength: 1,
            required: true, 
            disabled: false,
            subText: 'Show',
            pattern: '^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$'
        }
    ],
    buttons:[
        {
            label: 'Join the waitlist',
            action: 'submit',
            type: 'cta'
        }
    ]
}