<hg-auth-wizard-item [currentStep]="0">
    <span title>
        Welcome to Hagile
    </span>
    <span subtitle>            
        Hagile is a powerful tool that enhances your agile process, enabling streamlined software development, efficient cycles, and effective bug fixes.
    </span>
    <span content>
        <hg-button routerLink="/auth/theme-selection" [docked]="true" label="Let's get started" icon="briefcase_b" [iconColor]="'light'"></hg-button>
    </span>
</hg-auth-wizard-item>