<div class="marquee-container my-3">
    <div [ngClass]="{'text-white' : !darkMode, 'text-dark-blue': darkMode}" class="marquee-content">
        <span class="text-[4rem]">Agile Process</span>

        <span class="text-[4rem] sm:mx-7">
            <hg-icon name="arrow_right" size="marquee" [type]="iconColor"></hg-icon>
        </span>
        
        <span class="text-[4rem]">Hagile App</span>

        <span class="text-[4rem] sm:mx-7">
            <hg-icon name="arrow_right" size="marquee" [type]="iconColor"></hg-icon>
        </span>
        
        <span class="text-[4rem]">Agile Process</span>

        <span class="text-[4rem] sm:mx-7">
            <hg-icon name="arrow_right" size="marquee" [type]="iconColor"></hg-icon>
        </span>
        
        <span class="text-[4rem]">Hagile App</span>

        <span class="text-[4rem] sm:mx-7">
            <hg-icon name="arrow_right" size="marquee" [type]="iconColor"></hg-icon>
        </span>
        
        <span class="text-[4rem]">Agile Process</span>

        <span class="text-[4rem] sm:mx-7">
            <hg-icon name="arrow_right" size="marquee" [type]="iconColor"></hg-icon>
        </span>
        
        <span class="text-[4rem]">Hagile App</span>

        <span class="text-[4rem] sm:mx-7">
            <hg-icon name="arrow_right" size="marquee" [type]="iconColor"></hg-icon>
        </span>
        
        <span class="text-[4rem]">Agile Process</span>

        <span class="text-[4rem] sm:mx-7">
            <hg-icon name="arrow_right" size="marquee" [type]="iconColor"></hg-icon>
        </span>
        
        <span class="text-[4rem]">Hagile App</span>

        <span class="text-[4rem] sm:mx-7">
            <hg-icon name="arrow_right" size="marquee" [type]="iconColor"></hg-icon>
        </span>
        
        <span class="text-[4rem]">Agile Process</span>

        <span class="text-[4rem] sm:mx-7">
            <hg-icon name="arrow_right" size="marquee" [type]="iconColor"></hg-icon>
        </span>
        
        <span class="text-[4rem]">Hagile App</span>
    </div>
  </div>
  