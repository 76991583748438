import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormDetails } from 'src/app/shared/models/shared/form/form-details';
import { FormValue } from 'src/app/shared/models/shared/form/form-value';
import { PreLaunchFormDetails } from "src/assets/static-forms/pre-launch/pre-launch-form";

import { environment } from 'src/environments/environment'

import {
  createClient,
  SupabaseClient
} from '@supabase/supabase-js'
import { FormField } from 'src/app/shared/models/shared/form/form-field';
import { FormComponent } from 'src/app/shared/components/custom-components/forms/form/form.component';

@Component({
  selector: 'hg-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent {
  private supabase: SupabaseClient
  formDetails: FormDetails = PreLaunchFormDetails;
  displayForm: boolean = true;
  @ViewChild('formComponent', { static: false }) form!: FormComponent;

  constructor(private router: Router) {
    this.supabase = createClient(environment.supabaseUrl, environment.supabaseKey);
  }

  async handleFormSubmission(value: FormValue): Promise<void> {
    const email = value.at(0)?.value as string;
    await this.saveEmailToWaitlist(email);
  }

  async saveEmailToWaitlist(email: string): Promise<void> {
    const table = this.supabase.from('waitlist');

    const { error } = await table.insert({email});

    if (error) {
      (this.formDetails.fields.at(0) as FormField).value = email;
      
      (this.formDetails.fields.at(0) as FormField).customError = error?.details.includes('already exists') ? (
        `Your email, ${email} already exists in our waitlist`) : 'An error occured. Please try again';

      return;
    } else {
      (this.formDetails.fields.at(0) as FormField).customError = null;
    }

    this.form.resetForm();
    this.displayForm = false;
  }
}
